import LinearProgress from "@material-ui/core/LinearProgress";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { checkUser } from "./Database/Keycloak/checkUser";
import UserContext from "./UserContext";

const AuthContext = createContext({});
const Keycloak = typeof window !== "undefined" ? require("keycloak-js") : null;

export const AuthProvider = ({ children }) => {
  const router = new useRouter();
  const [loading, setLoading] = useState(true);
  const [idle, setIdle] = useState(false);
  let { keycloakstate, setKeycloakstate } = useContext(UserContext);

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: () => setIdle(true),
    onActive: () => setIdle(false),
    debounce: 500,
  });

  const getAuthentication = async () => {
    let keycloakId;
    let email;
    let lastName;
    let firstName;
    let gemiNumber;
    const keycloak = Keycloak("../../keycloak/" + process.env.keycloak);
    const keycloakJson = (
      await import(`../public/keycloak/${process.env.keycloak}`)
    ).default;
    await keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
      if (keycloak.realmAccess) {
        setKeycloakstate({
          keycloak: keycloak,
          authenticated: authenticated,
          token: keycloak.token,
          userName: keycloak.tokenParsed.name,
          user_id: keycloak.tokenParsed.sub,
          roles: keycloak.tokenParsed.realm_access.roles,
        });
        window.accessToken = keycloak.token;
        sessionStorage.setItem("authentication", keycloak.token);
        sessionStorage.setItem("refreshToken", keycloak.refreshToken);
        keycloakId = keycloak.tokenParsed.sub;
        email = keycloak.tokenParsed.email;
        lastName = keycloak.tokenParsed.family_name;
        firstName = keycloak.tokenParsed.given_name;
        gemiNumber = keycloak.tokenParsed.gemi_number;
      } else {
        var currentURL =
          window.location.protocol +
          "//" +
          window.location.host +
          "/unauthorizedAccess";
        window.location =
          keycloakJson["auth-server-url"] +
          "realms/" +
          keycloakJson["realm"] +
          "/protocol/openid-connect/logout?redirect_uri=" +
          currentURL;
      }
    });
    return { keycloakId, email, lastName, firstName, gemiNumber };
  };

  const keycloakAuthForUsers = async () => {
    let authCheck = false;
    const securePages = [
      "/",
      "/home",
      "/categories",
      "/categories/add",
      "/categories/edit/[category]",
      "/medias",
      "/medias/add",
      "/medias/edit/[media]",
      "/pages",
      "/pages/add",
      "/pages/edit/[page]",
      "/posts",
      "/posts/add",
      "/posts/edit/[post]",
      "/login",
    ];

    if (securePages.includes(router.pathname)) {
      authCheck = true;
    }
    return { authCheck };
  };

  const createUserSession = async (userInfos) => {
    let sessionUserInfo = {
      id: userInfos.id,
      lastName: userInfos.lastName,
      firstName: userInfos.firstName,
      isChamberUser: userInfos.isChamberUser,
      isAdmin: userInfos.isAdmin,
      companyId: userInfos.companyId,
      keycloakId: userInfos.keycloakId,
    };
    await sessionStorage.setItem(
      "userSession",
      JSON.stringify(sessionUserInfo)
    );

    return true;
  };

  const updateToken = async () => {
    // dont run any logic if logged out
    if (keycloakstate.authenticated) {
      // if user is not idle, update token
      if (!idle) {
        try {
          // update the token if it has less than 5 mins life
          await keycloakstate.keycloak.updateToken(5 * 60);
          // update the state
          setKeycloakstate({
            ...keycloakstate,
            token: keycloakstate.keycloak.token,
            userName: keycloakstate.keycloak.tokenParsed.name,
            user_id: keycloakstate.keycloak.tokenParsed.sub,
            roles: keycloakstate.keycloak.tokenParsed.realm_access.roles,
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        // if idle, just log out
        sessionStorage.removeItem("authentication");
        sessionStorage.removeItem("refreshToken");
        sessionStorage.removeItem("userSession");

        var currentURL = window.location.protocol + "//" + window.location.host;
        const keycloakJson = (
          await import(`../public/keycloak/${process.env.keycloak}`)
        ).default;
        window.location =
          keycloakJson["auth-server-url"] +
          "/realms/" +
          keycloakJson["realm"] +
          "/protocol/openid-connect/logout?redirect_uri=" +
          currentURL;
      }
    }
  };


  const keycloakFunc = async () => {
    setLoading(true);
    let { authCheck } = await keycloakAuthForUsers();
    if (
      window !== undefined &&
      keycloakstate.authenticated !== true &&
      authCheck === true
    ) {
        let { keycloakId, email, lastName, firstName, gemiNumber } =
        await getAuthentication();
      
      let userInfos = await checkUser(
        keycloakId,
        email,
        lastName,
        firstName,
        gemiNumber
      );

      await createUserSession(userInfos);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    keycloakFunc();
  }, [router.pathname]);

  useEffect(() => {
    // if keycloak is loaded, try to update token every minute
    if (keycloakstate?.keycloak?.updateToken && setKeycloakstate) {
      const interval = setInterval(() => updateToken(), 60 * 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [keycloakstate, setKeycloakstate, idle]);

  // update keycloak session when keycloak data changes
  useEffect(() => {
    if (keycloakstate?.keycloak && keycloakstate?.token) {
      window.accessToken = keycloakstate.token;
      sessionStorage.setItem("authentication", keycloakstate.token);
      sessionStorage.setItem(
        "refreshToken",
        keycloakstate.keycloak.refreshToken
      );
    }
  }, [keycloakstate]);

  return (
    <AuthContext.Provider value={{ loading }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const ProtectRoute = ({ children }) => {
  const { loading } = useAuth();
  if (loading) {
    return <LinearProgress />;
  }
  return children;
};
